import { fetchPrivilegeAPI, fetchPrivilegesAPI, procPrivilegeAPI } from '@/services/apis'

export default {
  namespaced: true,
  state: {
    privileges: [],
  },
  getters: {
    privilegeOptions(state) {
      if (state.privileges.length <= 0) {
        return []
      }
      return state.privileges.map(obj => ({
        label: obj.description,
        value: obj.privilegeId,
      }))
    },
  },
  mutations: {
    SET_PRIVILEGE_OPTIONS(state, data) {
      state.privileges = data
    },
  },
  actions: {
    async fetchPrivileges(ctx, queryParams) {
      try {
        const response = await this.$axios.get(fetchPrivilegesAPI, { params: queryParams })
        ctx.commit('SET_PRIVILEGE_OPTIONS', response.data.data)
        return response
      } catch (error) {
        return error
      }
    },
    async fetchPrivilege(ctx, id) {
      try {
        const response = await this.$axios.get(`${fetchPrivilegeAPI}/${id}`)
        return response
      } catch (error) {
        return error
      }
    },
    async procPrivilege(ctx, params) {
      try {
        const response = await this.$axios.post(procPrivilegeAPI, params)
        return response
      } catch (error) {
        return error
      }
    },
  },
}
