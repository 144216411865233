<template>

  <div>

    <!-- Filters -->
    <privileges-list-filters
      :description-filter.sync="descriptionFilter"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            v-if="$ability.can('C', 'Roles & Privileges')"
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                :to="{ name: 'system-settings-privilege-create' }"
              >
                <span class="text-nowrap">Add Privilege</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refPrivilegeListTable"
        class="position-relative"
        :items="fetchPrivileges"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-if="$ability.can('E', 'Roles & Privileges')"
              :to="{ name: 'system-settings-privilege-edit', params: { id: data.item.privilegeId } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$ability.can('D', 'Roles & Privileges')"
              @click="deletePrivilege({ privilegeId: data.item.privilegeId, operation: 'D' })"
            >
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            v-if="data.item.status"
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: Show Details -->
        <template #cell(show_details)="row">
          <b-button
            v-ripple.400="'rgba(53, 197, 240, 0.15))'"
            variant="flat-info"
            class="btn-icon rounded-circle"
            size="sm"
            @click="row.toggleDetails"
          >
            <feather-icon :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
          </b-button>
        </template>

        <!-- Column: Row Details -->
        <template #row-details="data">
          <privileges-operations-list :privilege-operations="data.item.operations" />
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BTable, BDropdown, BDropdownItem,
  BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import PrivilegesListFilters from './PrivilegesListFilters.vue'
import usePrivilegesList from './usePrivilegesList'
import privilegeStoreModule from '../privilegeStoreModule'
import { resolveStatus, resolveStatusVariant } from '@/utils/common-functions/resolvers'
import { statusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import PrivilegesOperationsList from '../privileges-operations-list/PrivilegesOperationsList.vue'

export default {
  components: {
    PrivilegesListFilters,

    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,

    PrivilegesOperationsList,
  },
  directives: {
    Ripple,
  },
  setup() {
    const PRIVILEGE_APP_STORE_MODULE_NAME = 'app-privilege'

    // Register module
    if (!store.hasModule(PRIVILEGE_APP_STORE_MODULE_NAME)) store.registerModule(PRIVILEGE_APP_STORE_MODULE_NAME, privilegeStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRIVILEGE_APP_STORE_MODULE_NAME)) store.unregisterModule(PRIVILEGE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchPrivileges,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPrivilegeListTable,
      refetchData,
      deletePrivilege,

      // UI
      // resolveStatusVariant,
      // resolveStatus,

      // statusOptions,

      // Extra Filters
      descriptionFilter,
      statusFilter,
    } = usePrivilegesList()

    return {

      fetchPrivileges,
      tableColumns,
      perPage,
      currentPage,
      totalRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPrivilegeListTable,
      refetchData,
      deletePrivilege,

      // UI
      resolveStatusVariant,
      resolveStatus,

      statusOptions,

      // Extra Filters
      descriptionFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
