<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="border-light"
    >

      <b-table
        ref="refPrivilegeOperationsListTable"
        class="position-relative"
        :items="privilegeOperations"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
        style="min-height: 150px"
      >

        <!-- Column: Description -->
        <template #cell(description)="data">
          {{ data.item.menu.description }}
        </template>

        <!-- Column: Operation -->
        <template #cell(operation)="data">
          {{ resolveOperation(data.item.operation) }}
        </template>

      </b-table>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import usePrivilegesOperationsList from './usePrivilegesOperationsList'
import { resolveOperation } from '@/utils/common-functions/resolvers'

export default {
  components: {
    BCard,
    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    privilegeOperations: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const {
      tableColumns,
    } = usePrivilegesOperationsList()

    return {
      tableColumns,

      resolveOperation,
    }
  },
}
</script>
