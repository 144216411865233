export default function usePrivilegesOperationsList() {
  // Table Handlers
  const tableColumns = [
    { key: 'menu.description', label: 'Description' },
    { key: 'menu.route', label: 'Route' },
    { key: 'operation' },
  ]

  return {
    tableColumns,
  }
}
