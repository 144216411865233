import { computed } from '@vue/composition-api'
import store from '@/store'

export const statusOptions = computed(() => store.getters['app-system-codes/statusOptions'])

export const sStatusOptions = computed(() => store.getters['app-system-codes/sStatusOptions'])

export const paymentMethodOptions = computed(() => store.getters['app-system-codes/paymentMethodOptions'])

export const paymentStatusOptions = computed(() => store.getters['app-system-codes/paymentStatusOptions'])

export const operationOptions = computed(() => store.getters['app-system-codes/operationOptions'])

export const userTypeOptions = computed(() => store.getters['app-system-codes/userTypeOptions'])

export const accountTypeOptions = computed(() => store.getters['app-system-codes/accountTypeOptions'])

export const tenureOptions = computed(() => store.getters['app-system-codes/tenureOptions'])

export const salesTargetStatusOptions = computed(() => store.getters['app-system-codes/salesTargetStatusOptions'])
