import {
  statusOptions,
  sStatusOptions,
  paymentMethodOptions,
  paymentStatusOptions,
  operationOptions,
  userTypeOptions,
  accountTypeOptions,
  tenureOptions,
  salesTargetStatusOptions,
} from '../computed-properties/system-codes/systemCodesComputedProperties'

export function resolveStatus(status) {
  const isStatus = statusOptions.value.find(obj => (obj.value === status))
  return isStatus ? isStatus.label : status
}

export function resolveStatusVariant(status) {
  if (status === 'A') return 'warning'
  if (status === 'C') return 'success'
  if (status === 'E') return 'info'
  if (status === 'D') return 'danger'
  if (status === 'V') return 'success'
  if (status === 'X') return 'danger'

  return 'primary'
}

export const resolveSStatus = status => {
  const isStatus = sStatusOptions.value.find(obj => (obj.value === status))
  return isStatus ? isStatus.label : status
}

export const resolveSStatusVariant = status => {
  if (status === 'A') return 'info'
  if (status === 'I') return 'warning'
  if (status === 'D') return 'danger'
  return 'primary'
}

export function resolvePaymentMethod(paymentMethod) {
  const isPaymentMethod = paymentMethodOptions.value.find(obj => (obj.value == paymentMethod))
  return isPaymentMethod ? isPaymentMethod.label : paymentMethod
}

export function resolvePaymentStatus(paymentStatus) {
  const isPaymentStatus = paymentStatusOptions.value.find(obj => (obj.value === paymentStatus))
  return isPaymentStatus ? isPaymentStatus.label : 'N/A'
}

export function resolveOperation(operation) {
  const isOperation = operationOptions.value.find(obj => (obj.value === operation))
  return isOperation ? isOperation.label : operation
}

export const resolveUserType = type => {
  const isUserType = userTypeOptions.value.find(obj => (obj.value === type))
  return isUserType ? isUserType.label : type
}

export const resolveUserTypeVariant = type => {
  switch (type) {
    case 'O':
      return 'warning'
    case 'D':
      return 'info'
    case 'A':
      return 'primary'
    case 'W':
      return 'success'

    default: return 'secondary'
  }
}

export const resolveUserTypeIcon = type => {
  switch (type) {
    case 'O':
      return 'ToolIcon'
    case 'D':
      return 'SidebarIcon'
    case 'A':
      return 'ServerIcon'
    case 'W':
      return 'GlobeIcon'

    default: return 'UserIcon'
  }
}

export function resolveAccountType(accountType) {
  const isAccountType = accountTypeOptions.value.find(obj => (obj.value === accountType))
  return isAccountType ? isAccountType.label : accountType
}

export function resolveTenure(tenure) {
  const isTenure = tenureOptions.value.find(obj => (obj.value === tenure))
  return isTenure ? isTenure.label : tenure
}

export function resolveSalesTargetStatus(salesTargetStatus) {
  const isSalesTargetStatus = salesTargetStatusOptions.value.find(obj => (obj.value === salesTargetStatus))
  return isSalesTargetStatus ? isSalesTargetStatus.label : salesTargetStatus
}

export const resolveSalesTargetStatusVariant = status => {
  if (status === 'A') return 'info'
  if (status === 'E') return 'danger'
  if (status === 'D') return 'dark'
  return 'primary'
}
