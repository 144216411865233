import { computed } from '@vue/composition-api'
import store from '@/store'

export default function useSystemCodes() {
  const isLoggedIn = computed(() => store.getters['app-authentication/isLoggedIn']).value

  const callUserType = () => {
    if (!isLoggedIn || localStorage.getItem('userTypeOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'UTP')
  }

  const callStatus = () => {
    if (!isLoggedIn || localStorage.getItem('statusOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'STS')
  }

  const callAccountType = () => {
    if (!isLoggedIn || localStorage.getItem('accountTypeOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'ATYP')
  }

  const callOperations = () => {
    if (!isLoggedIn || localStorage.getItem('operationOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'OPR')
  }

  const callSStatus = () => {
    if (!isLoggedIn || localStorage.getItem('sStatusOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'SSTS')
  }

  const callPaymentMethods = () => {
    if (!isLoggedIn || localStorage.getItem('paymentMethodOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'PMETHOD')
  }

  const callPaymentStatus = () => {
    if (!isLoggedIn || localStorage.getItem('paymentStatusOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'PSTS')
  }

  const callTenure = () => {
    if (!isLoggedIn || localStorage.getItem('tenureOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'TNR')
  }

  const callSalesTargetStatus = () => {
    if (!isLoggedIn || localStorage.getItem('salesTargetStatusOptions')) {
      return
    }

    store.dispatch('app-system-codes/fetchSystemCode', 'TSTS')
  }

  return {
    callUserType,
    callStatus,
    callSStatus,
    callAccountType,
    callOperations,
    callPaymentMethods,
    callPaymentStatus,
    callTenure,
    callSalesTargetStatus,
  }
}
