import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'

export default function usePrivilegesList() {
  // Use toast
  const toast = useToast()

  const refPrivilegeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'actions', sortable: false },
    { key: 'description', sortable: true },
    { key: 'status', sortable: true },
    {
      key: 'show_details',
      sortable: false,
      label: '',
      class: 'text-right',
    },
  ]
  const perPage = ref(10)
  const totalRecords = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const descriptionFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refPrivilegeListTable.value ? refPrivilegeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRecords.value,
    }
  })

  const refetchData = () => {
    refPrivilegeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, descriptionFilter, statusFilter], () => {
    refetchData()
  })

  const fetchPrivileges = (ctx, callback) => {
    store
      .dispatch('app-privilege/fetchPrivileges', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        description: descriptionFilter.value,
        status: statusFilter.value,
      })
      .then(response => {
        const records = response.data.data

        callback(records)
        totalRecords.value = records.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching Privileges list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deletePrivilege = async deleteParams => {
    await store.dispatch('app-privilege/procPrivilege', deleteParams)
    refetchData()
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // Call System Codes
  const { callOperations } = useSystemCodes()
  callOperations()

  return {
    fetchPrivileges,
    tableColumns,
    perPage,
    currentPage,
    totalRecords,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPrivilegeListTable,

    refetchData,
    deletePrivilege,

    // Extra Filters
    descriptionFilter,
    statusFilter,
  }
}
